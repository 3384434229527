
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SignOut from "layouts/signOut";
import IdVerification from "layouts/idVerification";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// @mui icons
import Icon from "@mui/material/Icon";
import AddWallets from "layouts/addWallets";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Add New Wallet",
    key: "add-wallet",
    icon: <AccountBalanceWalletIcon />,
    route: "/add-wallet",
    component: <AddWallets />,
  },
  {
    type: "collapse",
    name: "Identity Verification",
    key: "identity-verification",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/identity-verification",
    component: <IdVerification />,
  },
  {
    type: "collapse",
    name: "Staking",
    key: "info",
    icon: <AccountBalanceIcon />,
    route: "/my-info",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-out",
    component: <SignOut />,
  },
  {
    type: "title",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "title",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
