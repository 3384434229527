import { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { Navigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormControl from '@mui/material/FormControl';
// @mui material components
import Grid from "@mui/material/Grid";
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';




function Employment(props) {
  const [employmentStatus, setEmploymentStatus] = useState(props && props.info.employmentStatus ? props.info.employmentStatus : "");
  const [companyName, setCompanyName] = useState(props && props.info.companyName ? props.info.companyName : "");
  const [timeEmployed, setTime] = useState(props && props.info.timeEmployed ? props.info.timeEmployed :"");
  const [email, setEmail] = useState(props && props.email ? props.email :"");

  const saveData = async() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var grab = await fetch(`https://kyc.parapay.cloud:3333/employment-info/save?email=${email}&employmentStatus=${employmentStatus}&companyName=${companyName}&timeEmployed=${timeEmployed}`, requestOptions)
    var d = await grab.json();
    console.log(d)
  }



  return (
    
      <MDBox
        justifyContent="center"
        alignItems="center"
        bgColor={"white"}
        color="white"
        width="100%"
        height="100%"
        shadow="md"
        p={4}
        textAlign="center"
        borderRadius="lg"
        variant="gradient">
          
        <MDTypography variant="h3" fontWeight="medium" textTransform="capitalize" p={2}>
          Employment Information
        </MDTypography>
      
        <Grid container spacing={1} direction={"row"} justifyContent="center">
          <Grid item xs={12} md={6} lg={3} >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Job Status</InputLabel>
              <Select
                sx={{height:40}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={employmentStatus}
                label="Job Status"
                onChange={(d) => {setEmploymentStatus(d.target.value)}}
              >
                <MenuItem value={true}>Employed</MenuItem>
                <MenuItem value={false}>Unemployed</MenuItem>
              </Select>
            </FormControl>
            
          </Grid>
        </Grid>
        {employmentStatus == true ? 
        <>
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
          <Grid item xs={12} md={6} lg={3} >
            <MDInput
              size="large"
              fullWidth={true}
              variant="outlined"
              label="Company Name"
              placeholder="Company Name"
              value={companyName}
              onChange={(d) => {setCompanyName(d.target.value);}}
            />
          </Grid>
          
        </Grid>
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
            <Grid item xs={12} md={6} lg={3} >
                <FormControl fullWidth>
                  <InputLabel id="time">Employed For</InputLabel>
                  <Select
                    sx={{height:40}}
                    labelId="time"
                    id="time"
                    value={timeEmployed}
                    label="Employed For"
                    onChange={(d) => {setTime(d.target.value)}}
                  >
                    <MenuItem value={"5"}>More than 5 Years</MenuItem>
                    <MenuItem value={"1-5"}>1-5 Years</MenuItem>
                    <MenuItem value={"1"}>Less Than One Year</MenuItem>
                  </Select>
                </FormControl>
                
              </Grid>
        </Grid>
        </>
        : null}
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
          <Grid item xs={12} md={6} lg={3} >
            <MDBox justifyContent={"center"} >
              <MDButton
                color="secondary"
                size="small"
                fullWidth={true}
                onClick={()=>{ saveData(); props.updateProgress(60)}}
                >Next</MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    
  );
}

export default Employment;
