

// react-router-dom components
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { actions } from "react-table";

function Cover() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [action, setAction] = useState(undefined);
  const [vCode, setVCode] = useState("");

  const attemptSignUp = async() => {
      
      try {
        var username = email
        const { user } = await Auth.signUp({
            username,
            password
        });
        //console.log(user);
        //setEmail(username);
        setAction('confirm');
        } catch (error) {
            //setError(error);
            console.log('error signing up:', error);
        }
  }

  async function confirmSignUp() {
    try {
    let code = vCode;
    await Auth.confirmSignUp(email, code);
    setAction("complete")

    } catch (error) {
        
        console.log('error confirming sign up', error);
    }
    }
  return (
    
    <CoverLayout image={bgImage}>
      {action == "complete" ? <Navigate to="/authentication/sign-in" /> : 
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign Up
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" fullWidth value={email} onChange={(d) => {setEmail(d.target.value);}}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" variant="standard" fullWidth value={password} onChange={(d) => {setPassword(d.target.value);}}/>
            </MDBox>
            {action == 'confirm' ? 
            <>
            <MDBox mb={2}>
              <MDInput label="Verification Code" variant="standard" fullWidth value={vCode} onChange={(d) => {setVCode(d.target.value);}}/>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> 
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={()=>{confirmSignUp()}}>
                confirm
              </MDButton>
            </MDBox>
            </> 
            : 
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={()=>{attemptSignUp()}}>
                sign up
              </MDButton>
            </MDBox>}
            
            
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      }
    </CoverLayout>
  );
}

export default Cover;
