import { Auth } from 'aws-amplify';
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";
import { useMediaQuery } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";



import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";




// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { PriceChange } from '@mui/icons-material';

function Dashboard() {
  const [ email, setEmail ] = useState(undefined);
  const [info, setInfo] = useState(undefined);
  const [priceData, setPriceData] = useState({usd_price:0.00});
  const contract = "0xd16fd95d949f996e3808eeea0e3881c59e76ef1e";
  const chain = 1;
  const [chartData, setChartData] = useState({labels:[],datasets:[]});
  const [value,setValue] = useState(0.00);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [mWallets,setMWallets] = useState(undefined);
  const [wallets,setWallets] = useState(undefined);

  useEffect(() => {
      
    getUser();
    getPrice();
    getChart();
  }, [])

  useEffect(() => {
    if (priceData && info){
      const balanceSum = info.wallets.reduce((acc, curr) => acc + curr.balance, 0);
      const totalValue = balanceSum * priceData.usd_price;
      setValue(totalValue.toFixed(2));
    }
  },[priceData,info])

  const getChart = async () => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "3KeNTTgb5t7GMAFai3T0v4dlvB3LbFAV1LGCSq6O");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`https://api.lynxcrypto.app/v1/tokens/line-chart?tokenId=${contract}:${chain}&days=7`, requestOptions)
      .then(response => response.text())
      .then(result => {
        var r = JSON.parse(result);
        const formattedDates = r.labels.map((date) => {
          return new Date(date).toLocaleDateString("en-US", {month: "short", day: "numeric"})
      })
      console.log(r)



        var cO = {
          labels : formattedDates,
          datasets : [
            {
              label:'high',
              color:'success',
              data:r.datasets[0].data
            },
            {
              label:'average',
              color:'info',
              data:r.datasets[1].data
            },
            {
              label:'low',
              color:'default',
              data:r.datasets[2].data
            }
          ]
        }
        console.log(cO)
        setChartData(cO)})
      .catch(error => console.log('error', error));
  }

  const getUser = async() => {
    try {
      var userData = await Auth.currentAuthenticatedUser();
      setEmail(userData.attributes.email);
      var userI = await getData(userData.attributes.email)
      console.log(userI)
      if (userI.result == "No Data Found"){
        userI.result = {
          firstName: "",
          lastName: "",
          phoneNumber: "",
          address1:"",
          address2: "",
          city: "",
          state:"",
          zip:"",
          dob:"",
          wallets:[],
          paraBalance:0,
          nfts: []      } 
          saveData(userData.attributes.email)
      }
      console.log(userI.result);
      setInfo(userI.result);

      console.log(userI.result);
      userI.result.wallets = userI.result.wallets.map(wallet => {
        return {
          ...wallet,
          balance: `${wallet.balance.toLocaleString()}`
        };
    });
      
      const formattedWallets = userI.result.wallets.map(wallet => {
          return {
            ...wallet,
            walletAddress: `${wallet.walletAddress.slice(0, 8)} . . . ${wallet.walletAddress.slice(-4)}`
          };
      });

      setMWallets(formattedWallets)
      setWallets(userI.result.wallets)
      
      
      
      
    } catch (e){
      console.log(e.message)
      setEmail("noUser")
    }
    console.log(email)
  }

  const saveData = async(userEmail) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var grab = await fetch(`https://kyc.parapay.cloud:3333/basic-info/initialize?email=${userEmail}`, requestOptions)
    var d = await grab.json();
    console.log(d)
  }

  const getPrice = async() => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "3KeNTTgb5t7GMAFai3T0v4dlvB3LbFAV1LGCSq6O");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`https://api.lynxcrypto.app/v1/tokens/metadata?tokenId=${contract}:${chain}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let pD = JSON.parse(result)
        setPriceData(pD);
        
      })
      .catch(error => console.log('error', error));
  }

  const getData = async(email) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var grab = await fetch("https://kyc.parapay.cloud:3333/basic-info?email="+email, requestOptions)
    var d = await grab.json();
    return d
  }

  return (
    
    <DashboardLayout>
      <DashboardNavbar />
      {email == "noUser" ? <Navigate to="/authentication/sign-in" /> : 
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3.5}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="paid"
                title="Current Para Price"
                count={`$ ${priceData ? priceData.usd_price.toFixed(6): null}`}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Your Current Para Balance",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="wallet"
                title="Holdings Value"
                count={`$ ${parseFloat(value).toLocaleString()}`}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Your Para's Value",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2.5}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="wallet"
                title="Wallets"
                count={info ? info.wallets.length : null}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Your Wallets",
                }}
              />
            </MDBox>
          </Grid>
          
          
        
        <Grid item xs={12} md={6} lg={5}>
            <GradientLineChart
              icon={{ color: "info", component: "leaderboard" }}
              title="Para Chart"
              description="7 day PARA price movements"
              chart={chartData}
            />
            
         
          
        </Grid>
          <Grid item xs={12} md={6} lg={5} >
            <MDBox mb={1.5}>
            {isMobile ?
              <DataTable
              table={wallets ? {
                columns: [
                  { Header: "wallet", accessor: "walletAddress", width: "25%" },
                  { Header: "balance", accessor: "balance", width: "30%" }
                ],
                rows: mWallets
              } : {
                columns: [
                  { Header: "wallet", accessor: "walletAddress", width: "25%" },
                  { Header: "balance", accessor: "balance", width: "30%" }
                ],
                rows: []
              }} />
              :
              <DataTable
              table={wallets ? {
                columns: [
                  { Header: "wallet", accessor: "walletAddress", width: "25%" },
                  { Header: "balance", accessor: "balance", width: "30%" }
                ],
                rows: mWallets
              } : {
                columns: [
                  { Header: "wallet", accessor: "walletAddress", width: "25%" },
                  { Header: "balance", accessor: "balance", width: "30%" }
                ],
                rows: []
              }} />
            }
              </MDBox>
            
         
          
          </Grid>
        </Grid>
        
        
      </MDBox>
      
      }
    </DashboardLayout>
  );
}

export default Dashboard;
