import { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { Navigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

//imports
import BasicInfo from "./basicInfo";
import Employment from "./employment";
import IdUpload from "./idUpload";
import SelfieUpload from "./selfieUpload";
import Verification from "./verification";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDProgress from "components/MDProgress";
import Complete from "./complete";




function IdVerification() {
  const [progress,setProgress] = useState(20);
  const [email, setEmail] = useState("");
  
  const [ userInfo, setInfo] = useState(undefined);
  

  useEffect(() => {
    getUser();
  }, [progress])

  const updateProgress = (num) => {
    setProgress(num)
  };


  const getUser = async() => {
    try {
      var userData = await Auth.currentAuthenticatedUser();
      setEmail(userData.attributes.email);
      var userI = await getData(userData.attributes.email)
      if (userI.result == "No Data Found"){
        userI.result = {
          firstName: "",
          lastName: "",
          phoneNumber: "",
          address1:"",
          address2: "",
          city: "",
          state:"",
          zip:"",
          dob:""        } 
      }
      setInfo(userI.result);
      
    } catch (e){
      console.log(e.message)
      setEmail("noUser")
    }
    console.log(email)
  }

  const getData = async(email) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var grab = await fetch("https://kyc.parapay.cloud:3333/basic-info?email="+email, requestOptions)
    var d = await grab.json();
    return d
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDProgress value={progress} />
      {email == "noUser" ? <Navigate to="/authentication/sign-in" /> : null}
      {userInfo && progress == 20 ? <BasicInfo info={userInfo} email={email} updateProgress={updateProgress}/> 
      : progress == 40 ? <Employment info={userInfo} email={email} updateProgress={updateProgress}/> 
      : progress == 80 ? <IdUpload info={userInfo} email={email} updateProgress={updateProgress}/>
      : progress == 60 ? <SelfieUpload info={userInfo} email={email} updateProgress={updateProgress}/> 
      : progress == 100 ? <Complete info={userInfo} email={email} updateProgress={updateProgress}/> 
      : null}
      
    </DashboardLayout>
  );
}

export default IdVerification;
