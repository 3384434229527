import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDProgress from "components/MDProgress";


function Complete(props) {
  return (
    
    <MDBox
    justifyContent="center"
    alignItems="center"
    bgColor={"white"}
    color="white"
    width="100%"
    height="100%"
    shadow="md"
    p={4}
    textAlign="center"
    borderRadius="lg"
    variant="gradient">
      
    <MDTypography variant="h3" fontWeight="medium" textTransform="capitalize" p={2}>
      Complete
    </MDTypography>
    <MDTypography variant="caption"  p={1}>
      Your info has been submitted to our team. You will recieve an email with the results of your ID verification check.
    </MDTypography>
    
    <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
      <Grid item xs={12} md={6} lg={3} >
        <MDBox justifyContent={"center"} component={Link} to={"/dashboard"} >
          <MDButton
            color="secondary"
            size="small"
            fullWidth={true}
            >Dashboard</MDButton>
        </MDBox>
      </Grid>
    </Grid>
  </MDBox>
    
  );
}

export default Complete;
