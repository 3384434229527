import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormControl from '@mui/material/FormControl';
import MDAlert from "components/MDAlert";

// @mui material components
import Grid from "@mui/material/Grid";

import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

function BasicInfo(props) {
  const [progress,setProgress] = useState(20)
  const [firstName, setFirstName] = useState(props && props.info.firstName ? props.info.firstName : "");
  const [lastName, setLastName] = useState(props && props.info.lastName ? props.info.lastName : "");
  const [email, setEmail] = useState(props && props.email ? props.email :"");
  const [phoneNumber, setPhoneNumber] = useState(props && props.info.phone ? props.info.phone :"");
  const [address1, setAddress1] = useState(props && props.info.address1 ? props.info.address1 :"");
  const [address2, setAddress2] = useState(props && props.info.address2 ? props.info.address2 :"");
  const [city, setCity] = useState(props && props.info.city ? props.info.city :"");
  const [state, setState] = useState(props && props.info.state ? props.info.state :"");
  const [zip, setZip] = useState(props && props.info.zip ? props.info.zip :"");
  const [dob, setDOB] = useState(props && props.info.dob ? props.info.dob : "");
  const [invalid, setInvalid] = useState({});

  const saveData = async() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var grab = await fetch(`https://kyc.parapay.cloud:3333/basic-info/save?firstName=${firstName}&lastName=${lastName}&email=${email}&dob=${dob}&phone=${phoneNumber}&address1=${address1}&address2=${address2}&city=${city}&state=${state}&zip=${zip}`, requestOptions)
    var d = await grab.json();
    console.log(d)
  }

  const formatDOB = (value) => {
    return value.substring(0,10).replace(/^(\d{2})(\d{2})(\d{4})$/,'$1/$2/$3');
  }

  const validate = () => {
    var invalidFields = {};
    if (!firstName) {
      invalidFields.firstName = true;
    }
    if (!lastName) {
      invalidFields.lastName = true;
    }
    if (!email) {
      invalidFields.email = true;
    }
    if (!phoneNumber) {
      invalidFields.phoneNumber = true;
    }
    if (!address1) {
      invalidFields.address1 = true;
    }
    if (!city) {
      invalidFields.city = true;
    }
    if (!state) {
      invalidFields.state = true;
    }
    if (!zip) {
      invalidFields.zip = true;
    }
    if (!dob) {
      invalidFields.dob = true;
    }
    let dateFormat = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/](19|20)\d{2}$/;
    if(dob && !dob.match(dateFormat)) {
      invalidFields.dob = true;
    }
    if (Object.keys(invalidFields).length > 0) {
      setInvalid(invalidFields)
      return false;
    }
    return true;
    
  }
  


  return (
    
      <MDBox
        justifyContent="center"
        alignItems="center"
        bgColor={"white"}
        color="white"
        width="100%"
        height="100%"
        shadow="md"
        p={4}
        textAlign="center"
        borderRadius="lg"
        variant="gradient">
          
        <MDTypography variant="h3" fontWeight="medium" textTransform="capitalize" p={2}>
          Basic Info
        </MDTypography>
      
        <Grid container spacing={1} direction={"row"} justifyContent="center">
          <Grid item xs={12} md={6} lg={3} >
            <MDInput
              size="large"
              fullWidth={true}
              variant="outlined"
              label="First Name"
              placeholder="First Name"
              value={firstName}
              onChange={(d) => {props.info.firstName = d.target.value; setFirstName(d.target.value);}}
              required
              error={invalid.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} >
            <MDInput
                size="large"
                fullWidth={true}
                variant="outlined"
                label="Last Name"
                placeholder="Last Name"
                value={lastName}
                onChange={(d) => {props.info.lastName = d.target.value;setLastName(d.target.value);}}
                required
                error={invalid.lastName}
              />
          </Grid>
        </Grid>
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
          <Grid item xs={12} md={6} lg={3} >
            <MDInput
                size="large"
                fullWidth={true}
                variant="outlined"
                label="Email"
                placeholder="Email Address"
                disabled={true}
                value={email}
                required
                error={invalid.email}
              />
          </Grid>
          <Grid item xs={12} md={6} lg={3} >
            <MDInput
                size="large"
                fullWidth={true}
                variant="outlined"
                label="Phone Number"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(d) => {props.info.phoneNumber = d.target.value; setPhoneNumber(d.target.value);}}
                required
                error={invalid.phoneNumber}
              />
          </Grid>
        </Grid>
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
          <Grid item xs={12} md={6} lg={3} >
            <MDInput
                size="large"
                fullWidth={true}
                variant="outlined"
                label="Address"
                placeholder="Address"
                value={address1}
                onChange={(d) => {props.info.address1 = d.target.value; setAddress1(d.target.value);}}
                required
                error={invalid.address1}
              />
          </Grid>
          <Grid item xs={12} md={6} lg={3} >
            <MDInput
                size="large"
                fullWidth={true}
                variant="outlined"
                label="Address 2"
                placeholder="Address 2"
                value={address2}
                onChange={(d) => {props.info.address2 = d.target.value; setAddress2(d.target.value);}}
                
              />
          </Grid>
        </Grid>
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
          <Grid item xs={12} md={6} lg={3} >
            <MDInput
                size="large"
                fullWidth={true}
                variant="outlined"
                label="City"
                placeholder="City"
                value={city}
                onChange={(d) => {props.info.city = d.target.value; setCity(d.target.value);}}
                required
                error={invalid.city}
              />
          </Grid>
          <Grid item xs={12} md={6} lg={3} >
            <FormControl fullWidth>
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  sx={{height:40}}
                  labelId="state-label"
                  id="state-label-value"
                  value={state}
                  required
                  label="State"
                  onChange={(d) => {props.info.state = d.target.value; setState(d.target.value)}}
                  error={invalid.state}
                >
                  <MenuItem value={"AL"}>AL</MenuItem>
                  <MenuItem value={"AK"}>AK</MenuItem>
                  <MenuItem value={"AS"}>AS</MenuItem>
                  <MenuItem value={"AZ"}>AZ</MenuItem>
                  <MenuItem value={"AR"}>AR</MenuItem>
                  <MenuItem value={"CA"}>CA</MenuItem>
                  <MenuItem value={"CO"}>CO</MenuItem>
                  <MenuItem value={"CT"}>CT</MenuItem>
                  <MenuItem value={"DE"}>DE</MenuItem>
                  <MenuItem value={"DC"}>DC</MenuItem>
                  <MenuItem value={"FL"}>FL</MenuItem>
                  <MenuItem value={"GA"}>GA</MenuItem>
                  <MenuItem value={"GU"}>GU</MenuItem>
                  <MenuItem value={"HI"}>HI</MenuItem>
                  <MenuItem value={"ID"}>ID</MenuItem>
                  <MenuItem value={"IL"}>IL</MenuItem>
                  <MenuItem value={"IN"}>IN</MenuItem>
                  <MenuItem value={"IA"}>IA</MenuItem>
                  <MenuItem value={"KS"}>KS</MenuItem>
                  <MenuItem value={"KY"}>KY</MenuItem>
                  <MenuItem value={"LA"}>LA</MenuItem>
                  <MenuItem value={"ME"}>ME</MenuItem>
                  <MenuItem value={"MA"}>MI</MenuItem>
                  <MenuItem value={"MN"}>MN</MenuItem>
                  <MenuItem value={"MS"}>MS</MenuItem>
                  <MenuItem value={"MO"}>MO</MenuItem>
                  <MenuItem value={"MT"}>MT</MenuItem>
                  <MenuItem value={"NE"}>NE</MenuItem>
                  <MenuItem value={"NV"}>NV</MenuItem>
                  <MenuItem value={"NH"}>NH</MenuItem>
                  <MenuItem value={"NJ"}>NJ</MenuItem>
                  <MenuItem value={"NM"}>NM</MenuItem>
                  <MenuItem value={"NY"}>NY</MenuItem>
                  <MenuItem value={"NC"}>NC</MenuItem>
                  <MenuItem value={"ND"}>ND</MenuItem>
                  <MenuItem value={"OH"}>OH</MenuItem>
                  <MenuItem value={"OK"}>OK</MenuItem>
                  <MenuItem value={"OR"}>OR</MenuItem>
                  <MenuItem value={"PA"}>PA</MenuItem>
                  <MenuItem value={"PR"}>PR</MenuItem>
                  <MenuItem value={"RI"}>RI</MenuItem>
                  <MenuItem value={"SC"}>SC</MenuItem>
                  <MenuItem value={"SD"}>SD</MenuItem>
                  <MenuItem value={"TN"}>TN</MenuItem>
                  <MenuItem value={"TX"}>TX</MenuItem>
                  <MenuItem value={"UT"}>UT</MenuItem>
                  <MenuItem value={"VT"}>VT</MenuItem>
                  <MenuItem value={"VA"}>VA</MenuItem>
                  <MenuItem value={"VI"}>VI</MenuItem>
                  <MenuItem value={"WA"}>WA</MenuItem>
                  <MenuItem value={"RI"}>RI</MenuItem>
                  <MenuItem value={"WV"}>WV</MenuItem>
                  <MenuItem value={"WI"}>WI</MenuItem>
                  <MenuItem value={"WY"}>WY</MenuItem>
                </Select>
              </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
          <Grid item xs={12} md={6} lg={3} >
              <MDInput
                  size="large"
                  fullWidth={true}
                  variant="outlined"
                  label="Zip Code"
                  placeholder="Zip Code"
                  value={zip}
                  onChange={(d) => {props.info.zip = d.target.value; setZip(d.target.value);}}
                  required
                  error={invalid.zip}

                />
            </Grid>
            <Grid item xs={12} md={6} lg={3} >
                <MDInput
                    size="large"
                    fullWidth={true}
                    variant="outlined"
                    label="Date of Birth"
                    placeholder="MM/DD/YYYY"
                    error={invalid.dob}
                    value={dob}
                    onInput={(e) => {
                        let newValue = e.target.value;
                        if(newValue.match(/^[\d\/]*$/)){
                            if(newValue.length === 2 || newValue.length === 5) {
                                newValue += '/';
                            }
                            setDOB(newValue);
                            props.info.dob = e.target.value;
                        }
                    }}
                    required
                    floating={false}
                />
            </Grid>

        </Grid>
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
          <Grid item xs={12} md={6} lg={3} >
            <MDBox justifyContent={"center"} >
              <MDButton
                color="secondary"
                size="large"
                fullWidth={true}
                onClick={()=>{ 
                  if(validate()) {
                    // save data
                    saveData(); props.updateProgress(40)
                  } }}
                >Save</MDButton>
            </MDBox>
          </Grid>
        </Grid>
        
      </MDBox>
      
    
  );
}

export default BasicInfo;
