import { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import { Navigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormControl from '@mui/material/FormControl';
// @mui material components
import Grid from "@mui/material/Grid";
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { uploadFile } from 'react-s3';
import { Buffer } from "buffer";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;




function IdUpload(props) {
  const [email, setEmail] = useState(props && props.email ? props.email :"");
  const [uploaded, setUploaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cResult, setCResult] = useState(undefined);
  const [v, setV] = useState(undefined);
  const [idKey, setIdKey] = useState(props && props.info.idPath ? props.info.idPath : "");
  const [sKey, setSKey] = useState(props && props.info.selfiePath ? props.info.selfiePath : "");


  const compareImages = async() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    var grab = await fetch(`https://kyc.parapay.cloud:3333/id-comparison?email=${email}&idKey=${props.info.idPath}&sKey=${props.info.selfiePath}`, requestOptions)
    var d = await grab.json();
    var d = JSON.parse(d.body)
    setCResult(d.result)
  }


  const addressVerification = async(path) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "multipart/form-data");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders
    };
    let address1 = props.info.address1.split(" ")
    let address2 = props.info.address2.split(" ")
    let city = props.info.city.split(" ")
    

    let verificationText = [...address1,...address2,city,props.info.state,props.info.zip,props.info.firstName,props.info.lastName]

    var grab = await fetch(`https://kyc.parapay.cloud:3333/address-verification?sKey=${props.info.idPath}&vText=${verificationText}&email=${email}`, requestOptions)
    
    var d = await grab.json();
    var r = JSON.parse(d.body);
    console.log(r)
    if (r.result == true) {
      setV(true)
    } else {
      setV(false)
    }
  }

  const idCheck = async() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "multipart/form-data");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders
    };

    var grab = await fetch(`https://kyc.parapay.cloud:3333/id-check?path=${props.info.idPath}&email=${email}`, requestOptions)
    
    var d = await grab.json();
    var r = JSON.parse(d.body);
    console.log(r)
    if (r.result == true) {
      setV(true)
    } else {
      setV(false)
    }
  }

  

  const handleFileSelect = async (file) => {
    const fileData = {
      email: email,
      name: file.name,
      type: file.type,
      size: file.size,
      timestamp: new Date()
    };
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileData', JSON.stringify(fileData));
    try {
      const res = await fetch('https://kyc.parapay.cloud:3333/s3-upload/id', {
        method: 'POST',
        body: formData,
      });
      var d = await res.json();
      var r = JSON.parse(d.body);
      console.log(r);
      props.info.idPath = r.data.Key;
      setUploaded(true);
      await addressVerification();
      await idCheck();
      await compareImages();

      
    } catch (err) {
      console.log(err);
    }
  };

  //{selectedImage && (<img alt="not fount" width={"200px"} src={URL.createObjectURL(selectedImage)} />)}

  
  

  return (
    
      <MDBox
        justifyContent="center"
        alignItems="center"
        bgColor={"white"}
        color="white"
        width="100%"
        height="100%"
        shadow="md"
        p={4}
        textAlign="center"
        borderRadius="lg"
        variant="gradient">
          
        <MDTypography variant="h3" fontWeight="medium" textTransform="capitalize" p={2}>
          ID Upload
        </MDTypography>
        <MDTypography variant="caption"  p={1}>
        { !uploaded ? 
        "Upload an image of your drivers license or passport."
        : cResult === undefined ? "Hang tight. Verifying your identity." : cResult == true ? "Face ID verification successful." : "Face ID verification failed."}
        </MDTypography>
        
        <Grid container spacing={1} direction={"row"} justifyContent="center">
          <Grid item xs={12} md={6} lg={3} >
          
          
          <MDButton variant="contained" component="label" color="primary" size="small">
            Upload
            <input 
              hidden
              accept="image/*" 
              type="file" 
              onChange={(event) => {
                setSelectedImage(event.target.files[0]);
              }} />
          </MDButton>
          <MDTypography variant="caption"  p={1}>
              {selectedImage ? selectedImage.name : null}
          </MDTypography>
          
          </Grid>
        </Grid>
        <Grid container spacing={1} pt={2} direction={"row"} justifyContent="center" >
          <Grid item xs={12} md={6} lg={3} >
            <MDBox justifyContent={"center"} >
              <MDButton
                color="secondary"
                size="small"
                fullWidth={true}
                onClick={()=>{ if (!cResult) {
                  handleFileSelect(selectedImage);
                } else if (uploaded){
                  props.updateProgress(100);
                }
                }}
                >{!cResult ? "Upload Image" : "Next" }</MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    
  );
}

export default IdUpload;
