import { Auth } from 'aws-amplify';
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useWeb3React } from '@web3-react/core';
import { injected, walletconnect, walletlink } from './../../utils/connector';
const ethers = require('ethers');
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from '@mui/material';

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from 'components/MDButton';
import metamask from '../../assets/images/metamask.png';
import wallet_connect from '../../assets/images/wallet_connect.png';
import coinbase from '../../assets/images/coinbase.png';

import DataTable from "examples/Tables/DataTable";


function AddWallets() {
  const { account, library, activate } = useWeb3React();
  const [ email, setEmail ] = useState(undefined);
  const [wallets, setWallets] = useState(undefined);
  const [mWallets,setMWallets] = useState(undefined);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const getUser = async() => {
    try {
      var userData = await Auth.currentAuthenticatedUser();
      setEmail(userData.attributes.email);
      var userI = await getData(userData.attributes.email)
      
      if (userI.result == "No Data Found"){
        userI.result = { wallets: []} 
      }
      console.log(userI.result);
      userI.result.wallets = userI.result.wallets.map(wallet => {
        return {
          ...wallet,
          balance: `${wallet.balance.toLocaleString()}`
        };
    });
      
      const formattedWallets = userI.result.wallets.map(wallet => {
          return {
            ...wallet,
            walletAddress: `${wallet.walletAddress.slice(0, 4)}...${wallet.walletAddress.slice(-4)}`
          };
      });

      setMWallets(formattedWallets)
      setWallets(userI.result.wallets);
      
    } catch (e){
      console.log(e.message)
      setEmail("noUser")
    }
    console.log(email)
  }

  const getData = async(email) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var grab = await fetch("https://kyc.parapay.cloud:3333/basic-info?email="+email, requestOptions)
    var d = await grab.json();
    return d
  }

  useEffect(() => {
    getUser();
  }, [])

  useEffect(() => {
    getHoldings();
  }, [account])

  const saveData = async(wallet) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    var grab = await fetch(`https://kyc.parapay.cloud:3333/add-wallet?email=${email}&wallet=${JSON.stringify(wallet)}`, requestOptions)
    var d = await grab.json();
    console.log(d)
  }

  const getHoldings = async() => {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "3KeNTTgb5t7GMAFai3T0v4dlvB3LbFAV1LGCSq6O");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`https://api.lynxcrypto.app/v1/wallets/token-balance?chain=1&wallets=${account}&contract=0xd16fd95d949f996e3808eeea0e3881c59e76ef1e`, requestOptions)
      .then(response => response.text())
      .then(result => {
          let r = JSON.parse(result)
          console.log(r.walletBalances[0])
          if (!wallets.some((wallet) => wallet.walletAddress === r.walletBalances[0].walletAddress)) {
            saveData(r.walletBalances[0])
            
            setWallets((wall) => [...wall, r.walletBalances[0]]);
          }
      }
          )
      .catch(error => console.log('error', error));
    
  }

  const connectMetamask = async () => {
    return await activate(injected, (error) => console.log(error));
  };

  const connectWalletConnect = async () => {
    return await activate(walletconnect, (error) => console.log(error));
  };

  const connectCoinbaseWallet = async () => {
    return await activate(walletlink, (error) => console.log(error));
  };
  

  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {email == "noUser" ? <Navigate to="/authentication/sign-in" /> :
        <MDBox mt={5} mb={3}>
          <MDTypography variant="h4" p={1} align={'center'}>Connect a wallet to add it to your account.</MDTypography>
          <Grid container spacing={1} pt={2}>
            <Grid item xs={12} md={3} lg={3} >
            <Grid container spacing={2} direction="column" alignItems="center" >
                <Grid item xs={12} md={12} lg={12} width={'100%'}>
                  <MDButton
                    color="none"
                    size="medium"
                    fullWidth={true}
                    onClick={()=> connectMetamask()}
                    >
                    <img  
                      src={metamask}
                      width={50}
                      height={50}
                      alt="metamask"
                    />
                    <MDTypography p={1} align={'center'}>MetaMask</MDTypography>
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={12} lg={12} width={'100%'} >
                  <MDButton
                    color="none"
                    size="medium"
                    fullWidth={true}
                    onClick={()=> connectWalletConnect()}
                    >
                    <img  
                      src={wallet_connect}
                      width={50}
                      height={50}
                      alt="metamask"
                    />
                    <MDTypography p={1} align={'center'}>Wallet Connect</MDTypography>
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={12} lg={12} width={'100%'}>
                  <MDButton
                    color="none"
                    size="medium"
                    fullWidth={true}
                    onClick={()=> connectCoinbaseWallet()}
                    >
                    <img  
                      src={coinbase}
                      width={50}
                      height={50}
                      alt="metamask"
                    />
                    <MDTypography p={1} align={'center'}>Coinbase Wallet</MDTypography>
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={8} ml={2}>
            
            <MDBox mb={1.5}>
              {isMobile ?
              <DataTable
              table={wallets ? {
                columns: [
                  { Header: "wallet", accessor: "walletAddress", width: "25%" },
                  { Header: "balance", accessor: "balance", width: "30%" }
                ],
                rows: mWallets
              } : {
                columns: [
                  { Header: "wallet", accessor: "walletAddress", width: "25%" },
                  { Header: "balance", accessor: "balance", width: "30%" }
                ],
                rows: []
              }} />
              :
              <DataTable
              table={wallets ? {
                columns: [
                  { Header: "wallet", accessor: "walletAddress", width: "25%" },
                  { Header: "balance", accessor: "balance", width: "30%" }
                ],
                rows: wallets
              } : {
                columns: [
                  { Header: "wallet", accessor: "walletAddress", width: "25%" },
                  { Header: "balance", accessor: "balance", width: "30%" }
                ],
                rows: []
              }} />
            }
              </MDBox>
            
         
          
          </Grid>
          </Grid>
        </MDBox>
      
  }
    </DashboardLayout>
  );
}

export default AddWallets;
