


import React,{useEffect} from "react";
import { Auth } from 'aws-amplify';
import { Navigate } from "react-router-dom";



function SignOut() {
    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    useEffect(() => {
      
        signOut();
        
      }, [])
   
  return (
    
    <Navigate to="/authentication/sign-in" />
      
  );
}

export default SignOut;
